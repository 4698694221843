<!-- 二维码购买 -->
<template>
  <div class="qrPay-box">
    <Modal title="" v-model="show" footer-hide :mask-closable="false" class-name="vertical-center-modal pay-modal" width="440">
      <p class="pay-qrcode">
        <img :src="qrUrl" v-if="qrUrl">
      </p>
      <div class="qrcode-box">
        <div class="wx-pay">
          <i class="weixin-pay-icon"></i>
          使用微信支付
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { createOrder, createTransId, toWeixinNativePay, getTrans } from '@/api/pay'

export default {
  name: 'Pay',
  data() {
    return {
      applyType: null, // 6, "课程订单充值"  7, "图书订单"  8, "每日设计会员充值"  9, "姑婆会员充值"
      applyId: null,
      transId: null,
      id: '',
      show: false,
      qrUrl: '',
      timer: null
    }
  },
  mounted() {
    if (this.transId) {
      this.getQrUrl()
    } else {
      this.createTransId()
    }
  },
  computed: {
    ...mapState(['userInfo']),
  },
  watch: {
    show(val) {
      if(!val) {
        clearInterval(this.timer)
      }
    },
  },
  methods: {
    // 生成transId
    createTransId() {
      createOrder({
        id: this.id,
      }).then((res) => {
        // console.log(res)
        if (res.data.applyId) {
          this.applyType = res.data.applyType
          this.applyId = res.data.applyId
          createTransId({
            applyType: this.applyType,
            applyId: this.applyId,
          }).then((response) => {
            // console.log(response)
            if (response.transId) {
              this.transId = response.transId
              this.getQrUrl()
              this.getPayStatus()
            }
          })
        }
      })
    },
    getQrUrl() {
      this.qrUrl = toWeixinNativePay({
        transId: this.transId,
        sysType: this.$SysType,
        sessionKey: this.userInfo.token,
      })
    },
    // 轮训查询支付状态 
    getPayStatus() {
      this.timer = setInterval(() => {
        getTrans({
          applyType: this.applyType,
          applyId: this.applyId,
        }).then((res) => {
          // 已支付 status = 2
          if (res.payTransDetail.status == 2) {
            clearInterval(this.timer)
            // 刷新页面
            window.location.reload()
          }
        }).catch(() => {
          clearInterval(this.timer)
        })
      }, 5000);
    }
  },
}
</script>

<style lang="less">
.pay-modal {
  text-align: center;
  .ivu-modal {
    position: relative;
    left: -200px;
    top: -100px;

    .ivu-modal-body {
      padding: 30px 0 50px;
    }
  }

  .pay-qrcode {
    width: 200px;
    height: 200px;
    display: inline-block;
    margin: auto;
    background: #fff;
  }
  img {
    width: 200px;
    height: 200px;
    display: inline-block;
    margin: auto;
  }

  .qrcode-box {
    font-size: 16px;
    color: #000;

    .wx-pay {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .weixin-pay-icon {
      width: 18px;
      height: 18px;
      display: inline-block;
      margin-right: 10px;
      background: url('~@/assets/img/weixin-pay@2x.png') center center no-repeat;
      background-size: contain;
    }
  }
}
</style>
