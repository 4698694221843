<template>
  <div class="top" :class="`product-${$SysType}`">
    <div class="search-nav">
      <div class="search-nav-container">
        <ul>
          <li>
            <img class="logo" :src="require(`@/assets/img/logo-${$SysType}.png`)" @click="myIndex">
          </li>
          <!-- <li>
            <router-link :class="{'active': !currentClassifyId}" to="/">首页<span></span></router-link>
          </li> -->
          <li :class="{'active': item.id == currentClassifyId}" v-for="item in classifyList[$SysType]" :key="item.id">
            <router-link :to="'/list/'+item.id">{{item.name}}<span></span></router-link>
          </li>
          <li class="search-li">
            <Input class="search-input" search :placeholder="$SysType == 3 ? 'ps' : '优秀作品'" v-model="keyword" @on-search="onSearch" />
          </li>
        </ul>
        <div class="login-nav">
          <div v-show="!userInfo.userName">
            <span @click="gotoLogin(0)">登录</span>
            <span class="line">|</span>
            <span @click="gotoLogin(1)">注册</span>
          </div>
          <div v-show="!!userInfo.userName">
            <Dropdown>
              <p class="userName-p">
                <!-- <Avatar class="person-icon" icon="person" size="small" />  -->
                <span class="userName">{{userInfo.userName}} </span>
              </p>
              <DropdownMenu slot="list">
                <div class="my-page">
                  <div class="my-info" @click="myInfo">
                    <Icon type="md-home" />
                    <p>我的主页</p>
                  </div>
                  <div class="sign-out" @click="signOutFun">
                    <Icon type="md-log-out" />
                    <p>退出登录</p>
                  </div>
                </div>
              </DropdownMenu>
            </Dropdown>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  props: ['classifyId'],
  name: 'Header',
  data() {
    return {
      keyword: ''
    }
  },
  created() {
    this.isLogin();
    this.setCurrentClassifyId()
    this.loadClassifyList()
  },
  mounted() {},
  computed: {
    // ...mapGetters(['breadCrumbList']),
    ...mapState(['userInfo', 'classifyList', 'currentClassifyId'])
  },
  watch: {
    $route() {
      this.setCurrentClassifyId()
    },
  },
  methods: {
    ...mapActions([
      'signOut',
      'isLogin',
      'loadCurrentClassifyId',
      'loadClassifyList'
    ]),
    setCurrentClassifyId() {
      var classifyId = ''
      console.log(this.$route.name)
      switch (this.$route.name) {
        case 'Index':
          classifyId = ''
          break;
        case 'articleList':
        case 'articleListHome':
        // case 'articleDetail':
          classifyId = 'article'
          break;
        case 'openeyeList':
        case 'openeyeDetail':
          classifyId = 'openeye'
          break;
        case 'excellentList':
          classifyId = 'excellent'
          break;
        case 'courseList':
        case 'courseDetail':
          classifyId = 'course'
          break;
        case 'bookList':
        case 'bookListHome':
        case 'bookDetail':
          classifyId = 'book'
          break;
        case 'noticeList':
          classifyId = 'notice'
          break;
        case 'wendaList':
        case 'wendaDetail':
          classifyId = 'wenda'
          break;
        case 'vipList':
        classifyId = 'vip'
        break;
        default:
          // classifyId = ''
          classifyId = this.currentClassifyId
          break;
      }
      this.loadCurrentClassifyId(classifyId)
    },
    onSearch() {
      this.$router.push('/list/search?keyword=' + this.keyword );
    },
    gotoLogin(type) {
      this.$Login({
        isRegister: type || 0,
      })
    },
    myIndex() {
      this.$router.push('/')
    },
    myInfo () {
      this.$router.push('/home');
    },
    signOutFun() {
      this.signOut()
      this.$router.push('/')
    },
  },
  mounted() {},
}
</script>

<style lang="less" scoped>
.top {
  background-color: #fff;
}
img.logo {
  width: 224px;
  height: 36px;
  cursor: pointer;
}
.product-3 {
  img.logo {
    width: 155px !important;
  }
  .search-nav-container li:first-child {
    width: 159px !important;
  }
}
.search-nav {
  width: 100%;
  height: 60px;
  // border-bottom: 2px solid @theme-code;
}
.search-nav-container {
  width: @page-main-width;
  height: 60px;
  margin: 0px auto;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
}
.search-nav-container ul {
  margin: 0px;
  padding-left: 0px;
  list-style: none;
}
.search-nav-container li {
  cursor: pointer;
  margin-right: 30px;
  height: 60px;
  color: @theme-code;
  font-size: 14px;
  font-weight: bold;
  float: left;
  display: flex;
  align-items: center;
  font-family: MicrosoftYaHeiUI-Bold, MicrosoftYaHeiUI;

  &.search-li {
    margin-left: 50px;
  }

  &:first-child {
    width: @left-side-width;
    margin-right: 80px;
  }
  a {
    color: #666666;
    text-decoration: none;
    display: inline-block;
    line-height: 30px;
    span {
      display: block;
      width: 100%;
      height: 3px;
      background: #fff;
      border-radius: 3px;
    }
  }

  &.active a,
  &:hover a,
  a.active {
    color: #222222;
    font-weight: bold;
    span {
      background: @theme-code;
    }
  }
}

.icon-search {
  display: inline-block;
  vertical-align: middle;
  width: 12px;
  height: 12px;
  margin-left: 6px;
  background: url('~@/assets/img/icon-search.png') center center no-repeat;
  background-size: contain;

  position: relative;
  left: 20px;
}

.search-input /deep/input{
  height: 30px;
  border-radius: 15px;
  padding-left: 15px;
}

.login-nav {
  display: flex;
  align-items: center;
  color: #222222;
  font-size: 13px;

  > div {
    padding: 2px 10px;
    border-radius: 4px;
    background: @theme-code;
    span {
      cursor: pointer;
      &.line {
        margin: 0 5px;
      }
    }
  }
}


.userName-p {
  cursor: pointer;
}
.my-page {
  padding: 3px 5px;
  width: 180px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /deep/.ivu-icon {
    color: #999;
  }
}
.my-page a {
  margin: 0px;
  padding: 0px;
  border: none;
}
.my-info {
  padding: 5px;
  width: 50%;
  height: 100%;
  text-align: center;
  cursor: pointer;
}
.my-info:hover {
  box-shadow: 0px 0px 5px #ccc;
}
.my-info i {
  font-size: 28px;
}
.my-info p {
  font-size: 12px;
}
.sign-out {
  padding: 5px;
  width: 50%;
  height: 100%;
  text-align: center;
  cursor: pointer;
}
.sign-out:hover {
  box-shadow: 0px 0px 5px #ccc;
}
.sign-out i {
  font-size: 28px;
}
.sign-out p {
  font-size: 12px;
}
</style>
