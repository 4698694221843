<template>
  <div class="ivu-back-top-new" :class="classes" :style="styles">
    <div class="item qrcode">
      <Dropdown class="ivu-dropdown-new" placement="left">
        <div class="qrcode-mini-img">
          <img class="qrcode-mini"  :src="require('@/assets/img/qrcode-mini.png')" alt="">
        </div>
        <DropdownMenu class="drop-detail" slot="list">
          <img class="qrcode-img" :src="require('@/assets/img/qrcode.png')" alt="">
          <div>扫一扫</div>
          <div>识别二维码</div>
        </DropdownMenu>
      </Dropdown>
    </div>
    <div class="item to-top" @click="back">
      <Icon class="active" type="ios-arrow-up" />
    </div>
    <!-- <div :class="innerClasses">
      <i class="ivu-icon ivu-icon-ios-arrow-up"></i>
    </div> -->
  </div>
</template>
<script>
import { scrollTop } from '@/utils/tools'
const prefixCls = 'ivu-back-top'

export default {
  props: {
    height: {
      type: Number,
      default: 400,
    },
    bottom: {
      type: Number,
      default: 30,
    },
    right: {
      type: Number,
      default: 30,
    },
    duration: {
      type: Number,
      default: 1000,
    },
  },
  data() {
    return {
      backTop: false,
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll, false)
    window.addEventListener('resize', this.handleScroll, false)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll, false)
    window.removeEventListener('resize', this.handleScroll, false)
  },
  computed: {
    classes() {
      return [
        `${prefixCls}`,
        {
          [`${prefixCls}-show`]: this.backTop,
        },
      ]
    },
    styles() {
      return {
        bottom: `${this.bottom}px`,
        right: `${this.right}px`,
      }
    },
    innerClasses() {
      return `${prefixCls}-inner`
    },
  },
  methods: {
    handleScroll() {
      this.backTop = window.pageYOffset >= this.height
    },
    back() {
      const sTop = document.documentElement.scrollTop || document.body.scrollTop
      scrollTop(window, sTop, 0, this.duration)
      this.$emit('on-click')
    },
  },
}
</script>

<style lang="less">
.ivu-back-top-new .item {
  border: 1px solid #E8E8E8;
  background: #fff;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;



  &.qrcode {
    display: none; // 先隐藏
    border-bottom: none;
    color: @theme-code;
    .qrcode-mini-img {
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      img{
        width: 16px;
        height: 16px;
        display: inline-block;
      }
    }

    .qrcode-img {
      width: 80px;
      height: 80px;
      display: inline-block;
      margin: auto;
    }

    .drop-detail {
      text-align: center !important;
      font-size: 12px;
    }
  }
}
.ivu-back-top-new {
  .to-top {
    /deep/.ivu-back-top i{
      padding: 0;
    }
  }
} 
</style>
