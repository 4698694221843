<template>
  <!-- 二维码 只有首页展示 -->
  <div id="qr_wrap_default" v-show="$route.name == 'Index'">
    <div class="qr_open_wrap" style="display: none;">
      <div class="qr_open_wrap_cntr" id="qr_open" style="left: -100%;"></div>
    </div>
    <div class="qr_pop_wrap" style="display: block;">
      <div class="qr_pop_wrap_cntr">
        <div class="qr_pop_wrap_cntr_bg"></div>
        <div class="qr_pop_box">
          <div class="qr_pop_top">
            <div class="qr_pop_top_bg"></div>
          </div>
          <div class="qr_pop_pic">
            <!-- <img src="./banner_1.png"> -->
            <img src="http://pic.linghouer.com/9203/6260/426011914.jpg">
          </div>
          <div class="qr_pop_cnt">
            <div class="qr_pop_cnt_bg"></div>
            <div class="qr_pop_qrcode">
              <div class="qr_pop_hint">扫描二维码下载</div>
              <!-- <img src="./qr_1.png" width="115" height="115" alt=""> -->
              <img src="http://pic.linghouer.com/9411/2181/6288247755.jpg" width="115" height="115" alt="">
            </div>
          </div><a class="qr_wrap_close" title="关闭" id="qr_close">×</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      
    }
  },
}
</script>

<style lang="less"> 
.qr_open_wrap {
  display: none;
  position: fixed;
  z-index: 99999;
  left: 0;
  bottom: 40px;
  width: 47px;
  height: 126px;
  overflow: hidden;
}

.qr_open_wrap .qr_open_wrap_cntr {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: url(http://s1.gupowang.com/uploads/files/202101/54751ad10c6121d7e82790455ac5177d.png) no-repeat;
  background-size: contain;
  cursor: pointer;
}

.qr_pop_wrap {
  display: none;
  position: fixed;
  z-index: 99998;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 206px;
  overflow: hidden;
}

.qr_pop_wrap .qr_pop_wrap_cntr {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
}

.qr_pop_wrap .qr_pop_wrap_cntr_bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.85;
  background: #000000;
  z-index: -200;
}

.qr_pop_wrap .qr_pop_box .qr_pop_top {
  height: 30px;
  overflow: hidden;
  position: absolute;
  top: -30px;
  left: -32px;
}

.qr_pop_wrap .qr_pop_box .qr_pop_top_bg {
  width: 250px;
  height: 250px;
  opacity: 0.85;
  background: #000000;
  -webkit-border-radius: 100% 100% 0 0;
  -ms-border-radius: 100% 100% 0 0;
  -o-border-radius: 100% 100% 0 0;
  -moz-border-radius: 100% 100% 0 0;
  border-radius: 100% 100% 0 0;
  }

.qr_pop_wrap .qr_pop_box {
  position: relative;
  width: 1090px;
  height: 166px;
  margin: auto;
  font-size: 12px;
  font-weight: 1.5;
  color: #333;
}

.qr_pop_wrap .qr_pop_box .qr_pop_pic {
  position: absolute;
  left: 0;
  bottom: 0;
}

.qr_pop_wrap .qr_pop_pic img {
  position: absolute;
  left: 0;
  bottom: 0;
}

.qr_pop_wrap .qr_pop_cnt {
  position: absolute;
  top: 0;
  right: 82px;
  height: 100%;
  padding: 0 2px 0 16px;
  color: #fff;
}

.qr_pop_wrap .qr_pop_cnt_bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  opacity: 0.2;
  filter: alpha(opacity=20);
  z-index: -100;
}

.qr_pop_wrap .qr_pop_qrcode {
  position: relative;
  float: left;
  width: 115px;
  margin: 20px 14px 0 0;
}

.qr_pop_wrap .qr_pop_qrcode .qr_pop_hint {
  line-height: 1.5;
  text-align: center;
}

.qr_pop_wrap .qr_pop_qrcode img {
  margin-top: 6px;
}

.qr_pop_wrap .qr_wrap_close {
  position: absolute;
  top: 10px;
  right: 40px;
  font-size: 38px;
  line-height: 1.2;
  font-family: Simsun;
  color: #9aa1a8;
  outline: 0 none;
  text-decoration: none;
}

.qr_pop_wrap .qr_wrap_close:hover {
  text-decoration: none
}
</style>