// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue';
import App from './App';
import router from './router';
import store from './store';
import ViewUI from 'view-design';
// import 'view-design/dist/styles/iview.css';
import '@/assets/less/index.less'
import moment from 'moment'
import imagesLoaded from 'vue-images-loaded'


import { LoginBox } from '@/components/login'
import { PayBox } from '@/components/pay'
Vue.prototype.$Login = LoginBox.install
Vue.prototype.$Pay = PayBox.install
Vue.prototype.$Moment = moment

// 各种配置参数 
Vue.prototype.$SysType = process.env.VUE_APP_PRODUCT_SYSTYPE
Vue.prototype.$CollectName = process.env.VUE_APP_PROD_COLLECTNAME
Vue.prototype.$H5Host = process.env.VUE_APP_H5_HOST
Vue.prototype.$IndexRecommendCatId = process.env.VUE_APP_INDEX_RECOMMENT_ARTICLE_CATID
Vue.prototype.$ListExcellentArticleCatId = process.env.VUE_APP_LIST_EXCELLENT_ARTICLE_CATID
Vue.prototype.$ListNoticeArticleCatId = process.env.VUE_APP_LIST_NOTICE_ARTICLE_CATID
Vue.prototype.$ListDaohangArticleCatId = process.env.VUE_APP_LIST_DAOHANG_ARTICLE_CATID
Vue.prototype.$VipApplyP = process.env.VUE_APP_VIP_APPLY_P
Vue.prototype.$VipApplyType = process.env.VUE_APP_VIP_APPLY_TYPE


import APlayer from '@moefe/vue-aplayer';
Vue.use(APlayer, {
  defaultCover: 'https://github.com/u3u.png', // set the default cover
  productionTip: false, // disable console output
});

Vue.use(ViewUI);
Vue.config.productionTip = false;
// 图片加载指令
Vue.directive('imagesLoaded', imagesLoaded)

router.beforeEach((to, from, next) => {
  // 百度统计埋点
  if (window._hmt) {
    if (to.path) {
      window._hmt.push(['_trackPageview', to.fullPath])
    }
  }
  ViewUI.LoadingBar.start();
  next();
});

router.afterEach(route => {
  ViewUI.LoadingBar.finish();
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
