<!-- 开眼 -->
<template>
  <div class="list-item-show-info" @click="gotoDetail(item)">
    <div class="list-item-show-img" :style="{'background-image': 'url(' + item.cover + ')'}">
      <!-- <img :src="item.cover"/> -->
    </div>
    <div class="list-item-show-detail">
      <div class="list-item-show-detail-title">
        <span>{{item.title}}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['item'],
  name: 'OpeneyeItem',
  data() {
    return {}
  },
  methods: {
    gotoDetail(item) {
      // this.$router.push('/detail/openeye/'+ item.id)
      window.open('/detail/openeye/'+ item.id , '_blank')
    }
  },
}
</script>

<style lang="less" scoped>
.list-item-show-info {
  float: left;
  width: 24%;
  margin-bottom: 20px;
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  margin-right: 0.5%;
  margin-left: 0.5%;
  cursor: pointer;

  &:hover{
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.1);
    .list-item-show-img { 
      background-size: 110%;
      animation: my-hover 500ms;
    }
  }

  .list-item-show-img {
    position: relative;
    font-size: 0;
    width: 100%;
    height: 160px;
    border-radius: 8px 8px 0 0;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    animation: my-leave 500ms;
  }

  .list-item-show-detail {
    font-size: 12px;
    color: #8f8f8f;
    font-size: 14px;
    line-height: 20px;
    padding: 11px 10px;
    position: relative;

    .list-item-show-detail-title {
      font-size: 14px;
      font-weight: bold;
      color: #222222;
      line-height: 18px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  @keyframes my-hover{
    0% {
      background-size: 100%;
    }
    100% {
      background-size: 110%;
    }
  }

  @keyframes my-leave {
    0% {
      background-size: 110%;
    }
    100% {
      background-size: 100%;
    }
  }
}
</style>
