import axios from 'axios'
import store from '@/store'
// import { LoginBox } from '@/components/login'
import { Message } from 'view-design'
const sysType = process.env.VUE_APP_PRODUCT_SYSTYPE

// create an axios instance
const service = axios.create({
  baseURL: '', // process.env.VUE_APP_BASE_API, // url = base url + request url
  withCredentials: true, // send cookies when cross-domain requests
  timeout: 10000 // request timeout
})

// request拦截器
service.interceptors.request.use(
  config => {
    
    if (config.url.indexOf('?') > -1) {
      config.url = `${config.url}&type=${sysType}&sysType=${sysType}`
    } else {
      config.url = `${config.url}?type=${sysType}&sysType=${sysType}`
    }
    if (store.state.userInfo.token) {
      config.headers.sessionKey = store.state.userInfo.token // 让每个请求携带自定义token 请根据实际情况自行修改
      config.url = `${config.url}&sessionKey=${store.state.userInfo.token}`
    }
    return config
  },
  error => {
    // do something with request error
    return Promise.reject(error)
  }
)

// response 拦截器
service.interceptors.response.use(
  response => {
    /**
     * code为非200是抛错 可结合自己业务进行修改
     */
    if (response.status !== 200) {
      return Promise.reject(response.status)
    }
    
    console.log("【接口链接】", response.config.url);
    console.log("【请求入参】", response.config.params || response.config.data);
    console.log("【请求出参】", response.data);

    const res = response.data
    if (res.error) {
      res.error.msg && Message.error(res.error.msg)
      return Promise.reject(res.error.msg)
    }
    // if(res.response_code != '00') {
    //   // 未登录状态
    //   if(res.response_code == '111111') {
    //     LoginBox.install()
    //   }
    //   res.response_msg && Message.error(res.response_msg)
    //   return Promise.reject(res.response_msg)
    // }
   
    return Promise.resolve(res)
  },
  error => {
    const originRequest = error.config
    // 修改请求超时错误提示
    if (
      error.code === 'ECONNABORTED' &&
      error.message.indexOf('timeout') >= 0 &&
      !originRequest._retry
    ) {
      originRequest._retry = true
      return axios.request(originRequest)
    }
    return Promise.reject(error.response)
  }
)

export default service
