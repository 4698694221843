<template>
  <footer class="footer">
    <div class="service-intro">
      <div class="servece-info">
        <img :src="require(`@/assets/img/logo2-${$SysType}.png`)" @click="myIndex">
        <div class="qrcode-2" v-if="$SysType == 3">
          <img :src="require(`@/assets/img/qrCode2-${$SysType}.jpeg`)">
          <p>官方微信</p>
        </div>
        <div class="servece-type">
          <!-- <div class="servece-type-title">关于我们</div> -->
          <div class="servece-type-info">
            <ul>
              <li v-for="(item, i) in menuList" :key="item">
                <router-link :to="'/about/'+ i">{{item}}</router-link>
              </li>
            </ul>
          </div>
        </div>
        <div class="line"></div>
        <div class="servece-type servece-partners">
          <!-- <div class="servece-type-title">
            <span>友情链接</span>
          </div> -->
          <div class="servece-type-info" v-if="partnersList && partnersList.length">
            <ul>
              <li>友情链接：</li>
              <li v-for="(item, i) in partnersList" :key="i" @click="gotoPartner(item)">
                {{item.coopName}}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="bei">
        <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">苏ICP备20041492号</a>
      </div>
    </div>
    <Qrwarp v-if="$SysType == 3"></Qrwarp>
  </footer>
</template>

<script>
import Qrwarp from './Qrwarp.vue'
import { getPartnersList } from '@/api/home'

export default {
  name: 'Footer',
  data() {
    return {
      partnersList: [],
      current: 1,
      pages: 0,
      menuList: {
        1: '关于我们',
        2: '版权声明',
        3: '联系我们',
        4: '隐私政策',
        5: '常见问题',
      },
    }
  },
  created() {
    this.loadPartnersList()
  },
  methods: {
    prePage() {
      if (this.current <= 1) return
      this.current--
      this.loadPartnersList()
    },
    nextPage() {
      if (this.current >= this.pages) return
      this.current++
      this.loadPartnersList()
    },
    loadPartnersList() {
      getPartnersList({
        current: this.current,
        size: 20,
        status: 1
      }).then((res) => {
        this.partnersList = res.cooperationList
        this.pages = res.page.totalCounts
      })
    },
    gotoPartner(item) {
      item.hrefUrl && window.open(item.hrefUrl)
    },
    myIndex() {
      window.scrollTo(0, 0)
      this.$router.push('/')
    },
  },
  components: {
    Qrwarp
  }
}
</script>

<style lang="less" scoped>
/*****************************底 部 开 始*****************************/
.footer {
  width: 100%;
  min-width: @page-main-width;
  // margin-top: 30px;
  background: #1f1f1f;
  color: #999;
  position: absolute;
  bottom: 0;
}
.service-intro {
  margin: 0 auto;
  height: 221px;
  width: @page-main-width;
}

.servece-info {
  font-size: 12px;
  position: relative;

  img {
    width: 160px;
    position: relative;
    cursor: pointer;
    margin: 35px 0 15px;
  }

  .qrcode-2 {
    width: 100px;
    position: absolute;
    right: 10px;
    top: 20px;
    color: #fff;
    text-align: center;
    font-size: 14px;

    img {
      margin: 0;
      width: 100px;
    }
  }
}

.servece-type {
  display: flex;
  font-size: 14px;
  line-height: 18px;
  color: #fff;

  .servece-type-title {
    padding-right: 15px;
  }

  .servece-type-info ul {
    list-style: none;
    display: flex;
  }
  .servece-type-info li {
    cursor: pointer;
    padding-right: 15px;
    a {
      color: #fff;
    }
  }
}

.servece-partners {
  font-size: 12px;
  color: #999;
  display: block;
  line-height: 1.8;
  .servece-type-info li {
    a {
      color: #999;
    }
  }
}

.line {
  width: 100%;
  margin: 8px 0;
  height: 1px;
  background: rgba(255, 255, 255, 0.1);
}

.bei {
  position: absolute;
  bottom: 20px;
  font-size: 12px;
  a,
  a:hover {
    color: #999;
    text-decoration: none;
  }
}
/*****************************底 部 结 束*****************************/
</style>
