import Vue from 'vue'
import Pay from './Pay.vue'
import router from '@/router'
import store from '@/store/index'

const PayBox = Vue.extend({...Pay, router, store })

PayBox.install = function (options) {
  let instance = new PayBox({
    data: options
  }).$mount()
  Vue.nextTick(() => {
    instance.show = true
  })
}
export { PayBox }
