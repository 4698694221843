<template>
  <div class="nav-container">
    <div class="nav-body">
      <!-- 首页轮播图 -->
     <div class="nav-content" v-if="adType == 9">
        <a @click="goto(bannerList[0])" v-if="bannerList && bannerList.length == 1">
          <div class="carouse-image-bg" :style="{'background-image': 'url(' + bannerList[0].imgUrl + ')'}"></div>
        </a>
        <Carousel class="home-carousel" autoplay loop easing="" arrow="hover" :autoplay-speed="3000" v-else-if="bannerList">
            <CarouselItem  v-for="(item, index) in bannerList" :key="adType + index">
              <a @click="goto(item)">
                <div class="carouse-image-bg" :data-type="adType" :style="{'background-image': 'url(' + item.imgUrl + ')'}"></div>
              </a>
            </CarouselItem>
        </Carousel>
      </div>
      <!-- 课程页面轮播图 -->
      <div class="nav-content" v-if="adType == 10">
        <!-- 幻灯片 -->
        <a @click="goto(bannerList2[0])" v-if="bannerList2 && bannerList2.length == 1">
          <!-- <img class="carouse-image" :src="bannerList2[0].imgUrl" alt=""> -->
          <div class="carouse-image-bg" :style="{'background-image': 'url(' + bannerList2[0].imgUrl + ')'}"></div>
        </a>
        <Carousel class="home-carousel" autoplay loop easing="" arrow="hover" :autoplay-speed="3000" v-else-if="bannerList2">
            <CarouselItem  v-for="(item, index) in bannerList2" :key="adType + index">
              <a @click="goto(item)">
                <!-- <img class="carouse-image" :src="item.imgUrl" alt=""> -->
                <div class="carouse-image-bg" :data-type="adType" :style="{'background-image': 'url(' + item.imgUrl + ')'}"></div>
              </a>
            </CarouselItem>
        </Carousel>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  props: ['adType'],
  name: 'CarouselNav',
  data () {
    return {};
  },
  computed: {
    ...mapState(['bannerList', 'bannerList2'])
  },
  watch: {
    adType() {
      this.initBanner()
    }
  },
  mounted () {
    this.initBanner()
  },
  methods: {
    ...mapActions(['loadBannerList']),
    initBanner() {
      this.loadBannerList({
        adType: this.adType,
        showType: 1
      })
    },
    goto(item) {
      item.linkParam && item.linkParam != 'undefined' && window.open(item.linkParam)
    },
  },
};
</script>

<style lang="less" scoped>
.nav-container {
  width: 100%;
  height: 350px;
}
/*大的导航信息，包含导航，幻灯片等*/
.nav-body {
  // width: @page-main-width;
  height: 330px;
  margin: 0px auto;
  display: flex;
}

/*导航内容*/
.nav-content {
  // width: @page-main-width;
  height: 330px;
  width: 100%;

  /deep/.ivu-carousel-dots li > button {
    height: 4px;
  }

  /deep/.ivu-carousel-dots li.ivu-carousel-active > button {
    background-color: @theme-code;
    opacity: 1;
  }
}
/deep/.home-carousel{
  font-size: 0 !important;
  height: 330px;
  a {
    font-size: 0;
  }
}
/deep/.ivu-carousel-dots{
  bottom: 15px;
}
.carouse-image {
  width: 100%; 
  height: 330px;
  display: block;
  // border-left: 1px solid transparent;
  // border-right: 1px solid transparent;
  // border-top: none;
  // border-bottom: none;
  // box-sizing: border-box;
}
.carouse-image-bg {
  width: 100%;
  height: 330px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
}
</style>

<style>
  .ivu-carousel-arrow{
    background-color: rgb(31 45 61 / 47%);
  }
  .ivu-carousel-arrow.left {
    left: 50% !important;
    margin-left: -539px;
  }
  .ivu-carousel-arrow.right {
    right: 50% !important;
    margin-right: -539px;
  }
  @media (max-width: 1440px) {
    .ivu-carousel-arrow.left {
      margin-left: -539px;
    }
    .ivu-carousel-arrow.right {
      margin-right: -539px;
    }
  }
</style>
