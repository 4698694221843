

import request from '@/utils/request'

//  =======  课程支付 start  ======

// 生成applyType和applyId
export const createOrder = (params)=>{
  return request({
    url: `/api/course/order/add/${params.id}`,
    // params,
    method: "post"
  });
}

// 生成transId  参数applyType和applyId
export const createTransId = (params)=>{
  return request({
    url: `/api/payTrans/rechargePayOnline`,
    params,
    method: "get"
  });
}

//  =======  课程支付 end  ======

//  =======  VIP start  ====== 

// 获取基础信息 == 未使用到
// export const blackCardChannel = (params)=>{
//   return request({
//     url: `/api/blackCardChannel/${params.applyP}`,
//     data: params,
//     method: 'get'
//   })
// }

// 发送验证码
export const sendApplyCode = (params)=>{
  return request({
    url: `/api/sendApplyCode?mobile=${params['mobile']}`,
    // params,
    method: 'get'
  })
}

// 立即领取
export const cardApply = (params)=>{
  return request({
    url: `/api/cardApply?p=${params.applyP}`,
    data: params,
    method: 'post'
  })
}

// 创建订单 applyId  applyType 
export const payOnline = (params)=>{
  return request({
    url: `/api/payTrans/payOnline?applyId=${params.applyId}&applyType=${params.applyType}`,
    data: params,
    method: 'get'
  })
}

//  =======  VIP end  ====== 


// 二维码支付 transId
export const toWeixinNativePay = (params)=>{
  return `/api/weixin/to_weixin_native_pay?type=${params.sysType}&sysType=${params.sysType}&transId=${params.transId}&sessionKey=${params.sessionKey}`
}

// 查询支付订单状态
export const getTrans = (params)=>{
  return request({
    url: `/api/payTrans/getTrans`,
    params,
    method: "get"
  });
}
