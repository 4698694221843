<!-- 登录注册 -->
<template>
  <div>
    <Modal title="" v-model="show" footer-hide :mask-closable="false" class-name="vertical-center-modal" width="440">
      <Tabs class="login-tabs" :value="String(isRegister)" :animated="false" @on-click="onClickTab">
        <TabPane label="登录" name="0"></TabPane>
        <TabPane label="注册" name="1"></TabPane>
      </Tabs>
      <div class="login-content">
        <Form ref="formValidate" :model="formValidate" :rules="ruleValidate" :label-width="0" label-position="left">
          <FormItem label="" prop="userName" v-if="isRegister == 1">
            <Input v-model="formValidate.userName" placeholder="用户名"></Input>
          </FormItem>
          <FormItem label="" prop="userMobile">
            <Input v-model="formValidate.userMobile" placeholder="手机号"></Input>
          </FormItem>
          <FormItem label="" prop="checkCode">
            <div class="checkCode-group" :class="{disabled: formValidate.preventClick}">
              <Input class="checkCode-input" v-model="formValidate.checkCode" size="large" placeholder="验证码">
              <Button class="checkCode-btn" slot="append" @click="getCheckCode">{{formValidate.checkCodeText}}</Button>
              </Input>
            </div>
          </FormItem>
          <div class="modal-btn-group">
            <Button size="large" class="modal-btn" type="primary" @click="handleSubmit('formValidate')">{{isRegister == 1 ? '注册': '登录'}}</Button>
          </div>
          <div class="login-agreement">
            <Checkbox v-model="formValidate.isAgree">{{isRegister == 1 ? '注册': '登录'}}即代表您同意</Checkbox>
            <span @click="gotoAgreement(4)">隐私政策</span>
          </div>
        </Form>
      </div>
    </Modal>
  </div>
</template>

<script>
import {sendVerifyCode, reg, login, getUserInfo} from '@/api/login'
import { mapActions } from 'vuex'

export default {
  name: 'Login',
  data() {
    return {
      isRegister: 0,
      show: false,
      formValidate: {
        userName: '',
        userMobile: '',
        checkCode: '',
        t: null,
        second: 60, //倒计时60s
        checkCodeText: '发送验证码',
        preventClick: false, //获取验证码按钮权限
        isAgree: false,
      },
      ruleValidate: {
        userName: [
          { required: true, message: '请输入用户名,不能为空', trigger: 'blur' }
        ],
        userMobile: [
          { required: true, message: '手机号不能为空', trigger: 'blur' },
          { type: 'string', pattern: /^1[0-9]{10}$/, message: '手机号格式出错', trigger: 'blur' }
        ],
        checkCode: [
          { required: true, message: '必须填写验证码', trigger: 'blur' },
          { type: 'string', min: 4, max: 4, message: '验证码长度错误', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    ...mapActions(['login']),
    onClickTab(val) {
      this.isRegister = val
      this.formValidate.preventClick = false
      this.formValidate.checkCodeText  = '发送验证码'
      this.formValidate.second = 0
      clearTimeout(this.t)
    },
    async getCheckCode() {
      if (this.formValidate.preventClick) {
        return
      }
      if (this.formValidate.userMobile.length === 11) {
        this.formValidate.preventClick = true
        let res = await sendVerifyCode({
          mobile: this.formValidate.userMobile,
          // doType: this.isRegister == 1 ? '' : 'login'
        })
        // 测试环境打开
        // this.formValidate.checkCode = res.code
        this.timer()
        this.formValidate.checkCodeText = '剩余60s'
      } else {
        this.$Message.error({
          content: '请输入正确的手机号',
          duration: 3,
        })
      }
    },
    handleSubmit(name) {
      if(!this.formValidate.isAgree) {
        this.$Message.error({
          content: '请先阅读并同意相关协议',
          duration: 3,
        })
        return
      }
      this.$refs[name].validate((valid) => {
        if (valid) {
          if (this.isRegister == 1) {
            reg({
              name: this.formValidate.userName,
              mobile: this.formValidate.userMobile,
              mobileCode: this.formValidate.checkCode,
              devType: 4
            }).then(() => {
              this.onClickTab('0')
              this.formValidate.checkCode = ''
              this.$Message.success('注册成功，请登录');
            }).catch(error => {
              this.$Message.error({
                content: error,
                duration: 3,
              })
            })
          } else {
            login({
              mobile: this.formValidate.userMobile,
              mobileCode: this.formValidate.checkCode,
              devType: 4
            }).then((res) => {
              this.login({
                userName: this.formValidate.userNickName,
                userMobile: this.formValidate.userMobile,
                token: res.token
              })
              // 更新保存用户信息
              getUserInfo().then((res2) => {
                this.login({
                  userId: res2.usrInfo.userId,
                  userName: res2.usrInfo.name,
                  userMobile: this.formValidate.userMobile,
                  token: res.token,
                  level: res2.usrInfo.level
                }).then(result => {
                  if (result) {
                    this.show = false
                    this.$Message.success('登录成功')
                    // 刷新页面
                    window.location.reload()
                  }
                })
              })
            }).catch(error => {
              this.$Message.error({
                content: error,
                duration: 3,
              })
            })
          }
        }
      })
    },
    gotoAgreement(type) {
      let routeData = this.$router.resolve({ path: `/about/${type}`})
      window.open(routeData.href, '_blank')
    },
     /**
     * 倒计时
     */
    timer() {
      if (this.formValidate.second > 0) {
        this.formValidate.second--
        this.formValidate.checkCodeText  = '剩余 ' + this.formValidate.second + 'S'
        this.formValidate.t = setTimeout(this.timer, 1000)
      } else {
        this.formValidate.preventClick = false
        this.formValidate.second = 60
        this.formValidate.checkCodeText  = '发送验证码'
        clearTimeout(this.formValidate.t)
      }
    },
  },

  computed: {},
}
</script>

<style lang="less" scoped>
.login-tabs {
  /deep/.ivu-tabs-bar {
    text-align: center;
    border: none;
  }
  /deep/.ivu-tabs-nav-scroll {
    display: inline-block;
  }
  /deep/.ivu-tabs-tab {
    font-size: 20px !important;
    padding: 15px 16px;
  }
  /deep/.ivu-tabs-nav .ivu-tabs-tab-active {
    font-weight: bold;
  }
  /deep/.ivu-tabs-tab:hover {
    color: @theme-code;
  }
  /deep/.ivu-tabs-ink-bar {
    height: 4px;
  }
}

.login-content {
  margin: 20px;
  height: 300px;
  /deep/.ivu-form-item {
    margin-bottom: 30px;
  }
  /deep/.ivu-input {
    height: 40px;
    border-radius: 0;
  }
  .checkCode-group {
    /deep/.ivu-input-group-append {
      width: 120px;
      background: @theme-code;
      color: #ffffff;
      border-color: @theme-code;
    }
    &.disabled {
      /deep/.ivu-input-group-append {
        opacity: 0.6;
      }
    }

    /deep/.checkCode-input input{ 
      border-right: 1px solid @theme-code;
    }
    // display: flex;
    // .checkCode-input {
    //   flex: 1;
    // }
    // .checkCode-btn {
    //   display: inline-block;
    //   width: 120px;
    //   height: 40px;
    //   line-height: 40px;
    //   text-align: center;
    //   background: @theme-code;
    //   color: #FFFFFF;
    // }
  }
  .modal-btn-group {
    margin-top: 40px;
    .modal-btn {
      width: 100%;
    }
  }
  .login-agreement {
    margin-top: 15px;
    /deep/.ivu-checkbox-wrapper {
      margin-right: 0;
    }
    span {
      color: @theme-code;
      margin: 0 2px;
      cursor: pointer;
    }
  }
}
</style>
