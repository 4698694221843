import Vue from 'vue'
import Login from './Login.vue'
// import Register from './register.vue'
import router from '@/router'
import store from '@/store/index'

const LoginBox = Vue.extend({...Login, router, store })
// const regBox = Vue.extend(Register)

LoginBox.install = function (options) {
    if (options === undefined || options === null) {
      options = { isRegister: 0 }
    } else {
      options = options;
    }
  let instance = new LoginBox({
    data: options
  }).$mount()
  Vue.nextTick(() => {
    instance.show = true
  })
}
// regBox.install = function (options) {
//   if (options === undefined || options === null) {
//     options = { isOrganization: false }
//   } else {
//     options = options;
//   }
//   let instance = new regBox({
//     data: options
//   }).$mount()
//   Vue.nextTick(() => {
//     instance.show = true
//   })
// }
export { LoginBox }
