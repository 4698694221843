import Vue from 'vue';
import Vuex from 'vuex';
import * as actions from './actions';
import * as mutations from './mutations';
import * as getters from './getters';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isLoading: false, // 是否展示loading动画
   
    userInfo: { // 用户信息
      userId: '',
      level: 0,
      userName: '',
      userMobile: '',
      token: ''
    },

    bannerList: [], // 轮播图
    bannerList2: [], // 轮播图

    classifyList: [], // 首页筛选分类
    currentClassifyId: '', // 当前分类ID

    articleClassifyList: [], // 文章筛选分类
    articleSelectCatId: '', // 文章当前选中分类ID
    articleListData: {}, // 文章当前选中分类的列表

    searchClassifyList: [], // 搜索筛选分类
    currentSearchClassifyId: 0, // 当前搜索分类ID
    searchList: '', // 文章当前选中搜索的列表

    selectedBookType: 0, // 选中的图书分类
    bookListData: {}, // 图书列表

    courseListData: {}, // 课程列表
    openeyeListData: {}, // 开眼列表
    wendaListData: {}, // 问答列表
    
    homeCourseList: [], // 首页推荐课程列表
    homeArticleList: [], // 首页推荐文章列表
    homeOpeneyeList: [], // 首页推荐开眼列表
    homeBookList: [], // 首页每日读书列表
  },
  getters,
  actions,
  mutations
});
