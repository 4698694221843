<!-- 文章 -->
<template>
  <div class="article-item-show-info" @click="gotoDetail(item)">
    <div class="article-item-show-img" :style="{'background-image': 'url(' + item.cover + ')'}">
      <!-- <router-link :to="'/detail/article/' + item.id"><img :src="item.cover"/></router-link> -->
    </div>
    <div class="article-item-show-detail">
      <div class="article-item-show-detail-title">
        <span>{{item.title}}</span>
      </div>
      <!-- <div class="article-item-show-detail-sub-title">
        <span>{{item.authorName}}</span>
      </div> -->
      <div class="article-item-show-detail-bottom">
        <span class="auth-name">{{item.authorName}}</span>
        <div class="right">
          <span class="foot-it">
            {{item.viewCount}}<span class="icon icon-eye"></span>
          </span>
          <span class="foot-it">
            {{item.commentCount}}<span class="icon icon-yue"></span>
          </span>
          <span class="foot-it">
            {{item.favCount}}<span class="icon icon-collect"></span>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['item'],
  name: 'ArticleItem',
  data() {
    return {}
  },
  methods: {
    gotoDetail(item) {
      // this.$router.push('/detail/article/'+ item.id)
      window.open('/detail/article/' + item.id , '_blank')
    }
  },
}
</script>

<style lang="less" scoped>
.article-item-show-info {
  float: left;
  width: 49%;
  margin-bottom: 15px;
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  display: flex;
  margin-right: 0.5%;
  margin-left: 0.5%;
  cursor: pointer;

  &:hover{
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.1);
    .article-item-show-img { 
      background-size: 110%;
      animation: my-hover 500ms;
    }
  }
  
  .article-item-show-img {
    font-size: 0;
    width: 200px;
    height: 130px;
    border-radius: 8px 0 0 8px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    animation: my-leave 500ms;
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.1);
  }

  .article-item-show-detail {
    font-size: 12px;
    margin: 6px 0px;
    flex: 1;
    color: #8f8f8f;
    font-size: 14px;
    line-height: 20px;
    padding-left: 10px;
    position: relative;

    .article-item-show-detail-title {
      font-size: 16px;
      font-weight: bold;
      color: #222222;
      line-height: 20px;
      span{
        cursor: pointer;
      }
    }
    .article-item-show-detail-sub-title {
      font-size: 12px;
      color: #999999;
      line-height: 15px;
      margin-top: 5px;
    }
    .article-item-show-detail-bottom {
      position: absolute;
      bottom: 0;
      width: 80%;
      display: flex;
      justify-content: space-between;
      color: #222222;
      font-size: 14px;

      .right {
        display: flex;
        align-items: center;
      }
      .foot-it {
        color: #999;
        margin-right: 10px;
        font-size: 12px;
        display: flex;
        align-items: center;

        .icon {
          display: inline-block;
          vertical-align: middle;
          width: 12px;
          height: 12px;
          margin-left: 6px;

          &.icon-eye {
            background: url('~@/assets/img/icon-eye.png') center center
              no-repeat;
            background-size: contain;
          }
          &.icon-yue {
            background: url('~@/assets/img/icon-yue.png') center center
              no-repeat;
            background-size: contain;
          }
          &.icon-collect {
            background: url('~@/assets/img/icon-collect.png') center center
              no-repeat;
            background-size: contain;
          }
        }
      }
    }
  }

  @keyframes my-hover{
    0% {
      background-size: 100%;
    }
    100% {
      background-size: 110%;
    }
  }

  @keyframes my-leave {
    0% {
      background-size: 110%;
    }
    100% {
      background-size: 100%;
    }
  }
}
</style>
