<template>
  <div class="page-index">
    <CarouselNav :adType="9"></CarouselNav>
    <div class="main-container">
      <div class="container">
        <!-- 首页各类推荐 -->
        <div class="item-class">
          <div class="item-class-head">
            <span class="item-class-title">
              <span class="main-title">推荐视频</span>
              <span class="sub-title">VIDEO</span>
            </span>
            <span class="item-class-right">
              <router-link to="/list/course">
                查看更多教程 <Icon type="ios-arrow-forward" />
              </router-link>
            </span>
          </div>
          <div class="item-class-content">
            <div class="course-box">
              <div class="course-list">
                <template v-for="(item, index) in homeCourseList">
                  <CourseItem :item="item" :key="index"></CourseItem>
                </template>
              </div>
            </div>
          </div>
        </div>

        <div class="item-class">
          <div class="item-class-head">
            <span class="item-class-title">
              <span class="main-title">推荐文章</span>
              <span class="sub-title">ARTICLE</span>
            </span>
            <span class="item-class-right">
              <router-link to="/list/article">
                查看更多文章 <Icon type="ios-arrow-forward" />
              </router-link>
            </span>
          </div>
          <div class="item-class-content">
            <div class="article-box">
              <div class="article-list">
                <template v-for="(item, index) in homeArticleList">
                  <ArticleItem :item="item" :key="index"></ArticleItem>
                </template>
              </div>
            </div>
          </div>
        </div>

        <div class="item-class">
          <div class="item-class-head">
            <span class="item-class-title">
              <span class="main-title">开眼</span>
              <span class="sub-title">OPEN EYE</span>
            </span>
            <span class="item-class-right">
              <router-link to="/list/openeye">
                查看更多作品 <Icon type="ios-arrow-forward" />
              </router-link>
            </span>
          </div>
          <div class="item-class-content">
            <div class="openeye-box">
              <div class="openeye-list">
                <template v-for="(item, index) in homeOpeneyeList">
                  <OpeneyeItem :item="item" :key="index"></OpeneyeItem>
                </template>
              </div>
            </div>
          </div>
        </div>

        <div class="item-class" v-if="$SysType == 3">
          <div class="item-class-head">
            <span class="item-class-title">
              <span class="main-title">每日读书</span>
              <span class="sub-title">READ</span>
            </span>
            <span class="item-class-right">
              <router-link to="/list/book">
                查看更多精品书籍 <Icon type="ios-arrow-forward" />
              </router-link>
            </span>
          </div>
          <div class="item-class-content">
            <div class="book-box">
              <div class="book-list">
                <template v-for="(item, index) in homeBookList">
                  <BookItem :item="item" :key="index"></BookItem>
                </template>
              </div>
            </div>
          </div>
        </div>

        <div class="vip-box" v-if="$SysType == 3">
          <div class="left">
            <img :src="require('@/assets/img/icon-vip@2x.png')">
          </div>
          <div class="right">
            <div style="overflow: hidden;">
              <span><i class="ok-icon"></i> 全站课程免费学</span>
              <span><i class="ok-icon"></i> 思维导图免费看</span>
              <span><i class="ok-icon"></i> 免费赠送实体书</span>
              <span><i class="ok-icon"></i> 一键解锁训练营</span>
              <span><i class="ok-icon"></i> 免费抠图</span>
              <span><i class="ok-icon"></i> 免费视频剪辑</span>
              <span><i class="ok-icon"></i> 每日一练优先点评</span>
            </div>
            <Button size="large" type="primary" style="width: 400px; margin-top: 15px;" @click="gotoVip">立即领取</Button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CarouselNav from '@/components/header/CarouselNav'
import CourseItem from '@/components/list/CourseItem'
import ArticleItem from '@/components/list/ArticleItem'
import OpeneyeItem from '@/components/list/OpeneyeItem'
import BookItem from '@/components/list/BookItem'

import { mapState, mapActions } from 'vuex'
export default {
  name: 'Index',
  created() {
    this.loadHomeCourseList({
      current: 1,
      size: 8
    })
    this.loadHomeArticleList({
      recommendArticleCatId: this.$IndexRecommendCatId
    })
    this.loadHomeOpeneyeList({
      current: 1
    })
    this.loadHomeBookList({
      bookType: 0, //新书推荐
      current: 1
    })
  },
  data() {
    return {}
  },
  methods: {
    ...mapActions(['loadHomeArticleList', 'loadHomeCourseList', 'loadHomeOpeneyeList', 'loadHomeBookList']),
    gotoVip() {
      this.$router.push('/list/vip')
    }
  },
  computed: {
    ...mapState(['homeArticleList', 'homeCourseList', 'homeOpeneyeList', 'homeBookList']),
  },
  components: {
    CarouselNav,
    CourseItem,
    ArticleItem,
    OpeneyeItem,
    BookItem
  },
}
</script>

<style lang="less" scoped>
@import '~@/assets/less/vip';

.container {
  margin: 0px auto;
  width: @page-main-width;
  min-height: @main-min-height;
  background-color: #ffffff;
  padding-bottom: 30px;
}


.item-class {
  width: 100%;
  padding-top: 20px;
  padding-bottom: 5px;
  background-color: #fff;
  background-color: #fff;
  overflow: hidden;
}
.item-class-head {
  width: 100%;
  line-height: 1;
  padding: 5px 0;
  font-size: 20px;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.item-class-title {
  margin-left: 15px;
  display: flex;
  align-items: center;
  
  .main-title {
    font-weight: bold;
    color: #000;
    font-size: 22px;
    display: inline-block;
    border-left: 5px solid @theme-code;
    padding-left: 5px;
  }

  .sub-title {
    font-size: 18px;
    font-weight: 300;
    color: rgba(0, 0, 0, 0.4);
    margin-left: 10px;
  }
}
.item-class-right {
  cursor: pointer;
  font-size: 12px;
  margin-right: 18px;
  /deep/.ivu-icon {
    font-size: 16px;
    position: relative;
    left: -4px;
    top: 1px;
  }
  a {
    color: #999999;
  }
}
.item-class-content {
  width: 100%;
  cursor: pointer;
  float: left;
  margin-top: 10px;
  padding: 0 10px;
}

</style>
