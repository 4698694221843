<template>
  <div id="app">
    <Header></Header>
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>
    <!-- 部分路由不包含这个Footer -->
    <Footer v-if="excludeRoutes.indexOf($route.name) == -1"></Footer>
    <!-- 回到顶部 -->
    <BackTop :bottom="150" :right="right" ></BackTop>
  </div>
</template>

<script>
import Header from '@/components/header/Header'
import Footer from '@/components/footer/Footer';
import BackTop from '@/components/footer/BackTop';
export default {
  name: 'App',
  data () {
    return {
      excludeRoutes: [''],
      right: 0
    };
  },
  components: {
    Header,
    Footer,
    BackTop
  },
  mounted() {
    this.getRight()
    window.addEventListener('resize', this.getRight, false)

    if (this.$SysType == 6) {
      document.title = '作品展示系统—设计网站'
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.getRight, false)
  },
  methods: {
    getRight() {
      var right = ((document.documentElement.clientWidth || document.body.clientWidth) - 1200) / 2 - 60
      this.right = right > 30 ? right : 30
    }
  },
};
</script>

