import request from '@/utils/request'

// 发送验证码
// http://127.0.0.1:8019/api/sendVerifyCode?mobile=18351458199
export const sendVerifyCode = (params)=>{
  return request({
    url: `/api/sendCardVerifyCode`,
    params,
    method: "get"
  });
}

// 注册
export const reg = (data)=>{
  return request({
    url: `/api/reg`,
    data,
    method: "post"
  });
}

// 登录
export const login = (data)=>{
  return request({
    url: `/api/login`,
    data,
    method: "post"
  });
}

// 登出
export const logout = (params)=>{
  return request({
    url: `/api/logout`,
    params,
    method: "get"
  });
}

// 用户信息
export const getUserInfo = (params)=>{
  return request({
    url: `/api/usrInfo`,
    params,
    method: "get"
  });
}