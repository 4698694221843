import request from '@/utils/request'

// 首页banner列表
export const getBannerList = (params) => {
  return request({
    url: `/api/ad/list`,
    params,
    method: 'get',
  })
}

// 筛选分类
export const getClassifyList = (params) => {
  return new Promise((resolve, reject) => {
    const res = {
      6: [
        {
          id: 'article',
          name: '文章',
        },
        {
          id: 'openeye',
          name: '每日开眼',
        },
        {
          id: 'excellent', // 同文章
          name: '优秀作品展示',
        },
        {
          id: 'course',
          name: '课程',
        },
        {
          id: 'notice', // 同文章
          name: '通知',
        },
        {
          id: 'wenda',
          name: '问答',
        }
      ],
      3: [
        {
          id: 'openeye',
          name: '每日开眼',
        },
        {
          id: 'article',
          name: '文章',
        },
        {
          id: 'course',
          name: '课程',
        },
        {
          id: 'book',
          name: '图书',
        },
        // {
        //   id: "homework",
        //   name: "每日一练"
        // },
        {
          id: 'wenda',
          name: '问答',
        },
        {
          id: 'vip',
          name: '会员特权',
        },
      ]
    }
    resolve(res)
  })
}

// 类都展示接口
// 文章列表 == 中部的分类 icfs=2
// http://sit.api.meirisheji.com/dirtree/list?type=3&icfs=2
export const getDirtreeListByIcfs = (params) => {
  return request({
    url: `/api/dirtree/list`,
    params,
    method: 'get',
  })
}

// 文章列表 == 列表数据
// http://sit.api.meirisheji.com/article/queryPage/21/1
export const getArticleListByCatId = (params) => {
  return request({
    url: `/api/article/queryPageForWeb/${params.catId}/${params.current}/${params.size}`,
    params,
    method: 'get',
  })
}

// 首页推荐列表 - 推荐文章列表 47
export const getHomeArticleList = (params) => {
  return request({
    url: `/api/article/queryPage/${params.recommendArticleCatId}/1`,
    // params,
    method: 'get',
  })
}

// 合作伙伴列表
export const getPartnersList = (params) => {
  return request({
    url: `/api/api/coop/page`,
    params,
    method: 'get',
  })
}

// 内容管理列表
export const getContentList = (params) => {
  return request({
    url: `/api/content/page`,
    params,
    method: 'get',
  })
}

// 搜索分类
export const getSearchClassify = (params) => {
  return request({
    url: `/api/v3/search/classify`,
    params,
    method: 'get',
  })
}

// 搜索列表
export const getSearchList = (params) => {
  return request({
    url: `/api/v3/search/list`,
    params,
    method: 'get',
  })
}
