// 设置用户登录信息
export const SET_USER_LOGIN_INFO = (state, data) => {
  state.userInfo = data;
};

// 设置加载状态
export const SET_LOAD_STATUS = (state, status) => {
  state.isLoading = status;
};

// 设置轮播图
export const SET_BANNER_LIST = (state, data) => {
  state.bannerList = data;
};
export const SET_BANNER_LIST2 = (state, data) => {
  state.bannerList2 = data;
};

// 设置筛选分类
export const SET_CLASSIFY_LIST = (state, data) => {
  state.classifyList = data;
};

// 设置文章所有分类
export const SET_ARTICLE_CLASSIFY_LIST = (state, data) => {
  state.articleClassifyList = data;
};

// 设置文章所选中分类
export const SET_ARTICLE_SELECT_CATID = (state, data) => {
  state.articleSelectCatId = data;
};

// 设置文章所选中分类列表数据
export const SET_ARTICLE_LIST = (state, data) => {
  state.articleListData = data;
};

// 设置搜索所有分类
export const SET_SEARCH_CLASSIFY_LIST = (state, data) => {
  state.searchClassifyList = data;
};

// 设置搜索所选中分类
export const SET_CURRENT_SEARCH_CLASSIFY_ID = (state, data) => {
  state.currentSearchClassifyId = data;
};

// 设置搜索所选中分类列表数据
export const SET_SEARCH_LIST = (state, data) => {
  state.searchList = data;
};

// 设置商品列表(搜索)
// export const SET_GOODS_LIST = (state, data) => {
//   state.goodsList = data;
// };

// 设置父类ID
export const SET_CURRENT_CLASSIFY_ID = (state, data) => {
  state.currentClassifyId = data;
};


// 设置首页推荐课程列表
export const SET_HOME_COURSE_LIST = (state, data) => {
  state.homeCourseList = data;
};

// 设置首页推荐文章列表
export const SET_HOME_ARTICLE_LIST = (state, data) => {
  state.homeArticleList = data;
};

// 设置首页推荐开眼列表
export const SET_HOME_OPENEYE_LIST = (state, data) => {
  state.homeOpeneyeList = data;
};

// 设置首页推荐图书列表
export const SET_HOME_BOOK_LIST = (state, data) => {
  state.homeBookList = data;
};

//设置课程列表
export const SET_COURSE_LIST = (state, data) => {
  state.courseListData = data;
};

//设置图书所选中分类
export const SET_SELECTED_BOOK_TYPE = (state, data) => {
  state.selectedBookType = data;
};

//设置图书列表
export const SET_BOOK_LIST = (state, data) => {
  state.bookListData = data;
};

// 设置开眼列表
export const SET_OPENEYE_LIST = (state, data) => {
  state.openeyeListData = data;
};

// 设置问答列表
export const SET_WENDA_LIST = (state, data) => {
  state.wendaListData = data;
};
