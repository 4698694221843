import Vue from 'vue';
import VueRouter from 'vue-router';
import Index from '@/views/Index';
const ListIndex = resolve => require(['@/views/List/Index'], resolve);
const SearchList = resolve => require(['@/views/List/Search'], resolve);
const ArticleList = resolve => require(['@/views/List/Article'], resolve);
const CourseList = resolve => require(['@/views/List/Course'], resolve);
const BookList = resolve => require(['@/views/List/Book'], resolve);
const OpeneyeList = resolve => require(['@/views/List/Openeye'], resolve);
const WendaList = resolve => require(['@/views/List/Wenda'], resolve);
const VipList = resolve => require(['@/views/List/Vip'], resolve);


const DetailIndex = resolve => require(['@/views/Detail/Index'], resolve);
const ArticleDetail = resolve => require(['@/views/Detail/Article'], resolve);
const CourseDetail = resolve => require(['@/views/Detail/Course'], resolve);
const BookDetail = resolve => require(['@/views/Detail/Book'], resolve);
const OpeneyeDetail = resolve => require(['@/views/Detail/Openeye'], resolve);
const WendaDetail = resolve => require(['@/views/Detail/Wenda'], resolve);


const Home = resolve => require(['@/views/Home'], resolve);
const About = resolve => require(['@/views/About'], resolve);


Vue.use(VueRouter);

//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

export default new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/', // 首页
      name: 'Index',
      component: Index,
      meta: {
        keepAlive: true
      }
    },
    {
      path: '/list', // 列表
      component: ListIndex,
      children: [{
        path: 'search',
        name: 'searchList',
        component: SearchList,
        meta: {
        	keepAlive: true
        }
      }, {
        path: 'article',
        name: 'articleListHome',
        component: ArticleList,
        meta: {
        	keepAlive: true
        }
      }, {
        path: 'article/:catId',
        name: 'articleList',
        component: ArticleList,
        meta: {
        	keepAlive: true
        }
      }, {
        path: 'course',
        name: 'courseList',
        component: CourseList,
        meta: {
        	keepAlive: true
        }
      }, {
        path: 'book',
        name: 'bookListHome',
        component: BookList,
        meta: {
        	keepAlive: true
        }
      }, {
        path: 'book/:bookType',
        name: 'bookList',
        component: BookList,
        meta: {
        	keepAlive: true
        }
      }, {
        path: 'openeye',
        name: 'openeyeList',
        component: OpeneyeList,
        meta: {
        	keepAlive: true
        }
      }, {
        path: 'excellent',
        name: 'excellentList', // 优秀作品
        component: ArticleList,
        meta: {
        	keepAlive: true
        }
      }, {
        path: 'notice',
        name: 'noticeList', // 通知
        component: ArticleList,
        meta: {
        	keepAlive: true
        }
      }, {
        path: 'wenda',
        name: 'wendaList', // 问答
        component: WendaList,
        meta: {
        	keepAlive: true
        }
      }, {
        path: 'vip',
        name: 'vipList', // vip
        component: VipList,
        meta: {
        	keepAlive: false
        }
      }]
    },
    {
      path: '/detail', // 详情
      component: DetailIndex,
      children: [{
        path: 'article/:id',
        name: 'articleDetail',
        component: ArticleDetail,
        meta: {
        	keepAlive: false
        }
      }, {
        path: 'course/:id',
        name: 'courseDetail',
        component: CourseDetail,
        meta: {
        	keepAlive: false
        }
      }, {
        path: 'book/:id',
        name: 'bookDetail',
        component: BookDetail,
        meta: {
        	keepAlive: false
        }
      }, {
        path: 'openeye/:id',
        name: 'openeyeDetail',
        component: OpeneyeDetail,
        meta: {
        	keepAlive: false
        }
      }, {
        path: 'wenda/:id',
        name: 'wendaDetail',
        component: WendaDetail,
        meta: {
        	keepAlive: false
        }
      }]
    },
    {
      path: '/about/:type',
      name: 'About',
      component: About,
      meta: {
        keepAlive: true
      }
    },
    {
      path: '/home', // 个人中心
      name: 'Home',
      component: Home,
      meta: {
        keepAlive: false
      }
    },
  ]
});
