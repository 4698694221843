import request from '@/utils/request'

// 课程列表
export const getCourseList = (params)=>{
  return request({
    url: `/api/course/list/${params.current}/${params.size}`,
    method: "get"
  });
}

// 课程详情
export const getCourseDetail = (params)=>{
  return request({
    url: `/api/course/info/${params.id}`,
    method: "get"
  });
}

// 开眼列表
export const getOpeneyeList = (params)=>{
  return request({
    url: `/api/v3/openeye/list/${params.current}`,
    params,
    method: "get"
  });
}

// 开眼详情
export const getOpeneyeDetail = (params)=>{
  return request({
    url: `/api/v3/openeye/info/${params.id}`,
    method: "get"
  });
}

// 问答列表
export const getWendaList = (params)=>{
  return request({
    url: `/api/wenda/queryPageForWeb/${params.current}/${params.size}`,
    method: "get"
  });
}

// 图书列表 0: 新书推荐  1: 热门图书
export const getBookList = (params)=>{
  return request({
    url: `/api/v3/book/listForWeb`,
    params,
    method: "get"
  });
}

// 图书详情
export const getBookDetail = (params)=>{
  return request({
    url: `/api/v3/book/info/${params.id}`,
    // params,
    method: "get"
  });
}

// 图书详情-好课推荐
export const getBookHotCoursesList = (params)=>{
  return request({
    url: `/api/v3/book/info/${params.id}/hotCoursesForWeb/${params.current}/${params.size}`,
    // params,
    method: "get"
  });
}

// 图书详情-热文推荐
export const getBookHotArticlesList = (params)=>{
  return request({
    url: `/api/v3/book/info/${params.id}/hotArticlesForWeb/${params.current}/${params.size}`,
    // params,
    method: "get"
  });
}
