<!-- 课程 -->
<template>
  <div class="list-item-show-info" @click="gotoDetail(item)">
    <div class="list-item-show-img" v-if="item.videoUrl">
      <video :src="item.videoUrl"></video>
      <div class="play-bg">
        <img :src="require('@/assets/img/play@2x.png')">
      </div>
    </div>
    <div class="list-item-show-img" v-else :style="{'background-image': 'url(' + (item.coverUrl || item.cover) + ')'}">
      <div class="play-bg">
        <img :src="require('@/assets/img/play@2x.png')">
      </div>
    </div>
    <div class="list-item-show-detail">
      <div class="list-item-show-detail-title">
        <span>{{item.name}}</span>
      </div>
    </div>
    
    <Modal class="list-item-show-modal" :footer-hide="true" v-model="modal" :title="item.name">
      <video v-if="modal" width="720" height="500" class="list-item-show-video" controls controlslist="nodownload" autoplay :src="item.videoUrl"></video>
    </Modal>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: ['item', 'type'], // type: 2  图书详情的配套视频 点击弹出播放
  name: 'CourseItem',
  data() {
    return {
      modal: false
    }
  },
  methods: {
    gotoDetail(item) {
      if (this.type === 2) {
        if (!this.userInfo.token) {
          this.$Login(0)
          return
        }
        this.modal = true
      } else {
        // this.$router.push('/detail/course/'+ item.id)
        window.open('/detail/course/' + item.id , '_blank')
      }
    }
  },
  computed: {
    ...mapState(['userInfo'])
  },
}
</script>

<style lang="less" scoped>
.list-item-show-info {
  float: left;
  width: 24%;
  margin-bottom: 20px;
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  margin-right: 0.5%;
  margin-left: 0.5%;
  cursor: pointer;

  &:hover{
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.1);
    .list-item-show-img { 
      background-size: 110%;
      animation: my-hover 500ms;
    }
  }

  .list-item-show-img {
    position: relative;
    font-size: 0;
    width: 100%;
    height: 160px;
    border-radius: 8px 8px 0 0;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    animation: my-leave 500ms;

    video {
      width: 100%;
      height: 160px;
    }

    .play-bg {
      position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 160px;
        background: rgba(0, 0, 0, 0.2);
        border-radius: 8px 8px 0 0;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1000;
        img {
          width: 40px;
        }
    }
  }

  .list-item-show-detail {
    font-size: 12px;
    margin: 6px 0px;
    color: #8f8f8f;
    font-size: 14px;
    line-height: 20px;
    height: 28px;
    padding: 5px 10px;
    position: relative;

    .list-item-show-detail-title {
      font-size: 14px;
      font-weight: bold;
      color: #222222;
      line-height: 18px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  @keyframes my-hover{
    0% {
      background-size: 100%;
    }
    100% {
      background-size: 110%;
    }
  }

  @keyframes my-leave {
    0% {
      background-size: 110%;
    }
    100% {
      background-size: 100%;
    }
  }
}
</style>

<style lang="less">
.list-item-show-modal .ivu-modal {
  width: 760px !important;
  background: rgba(0, 0, 0, 0.2);
  top: 5vh;
}
</style>
