import { getCourseList, getOpeneyeList, getBookList, getWendaList } from '@/api/product'
import { getHomeArticleList, getClassifyList, getBannerList } from '@/api/home'
import { getDirtreeListByIcfs, getArticleListByCatId, getSearchClassify, getSearchList } from '@/api/home'
import { getUserInfo } from '@/api/login'

// 用户登录
export const login = ({ commit }, data) => {
  return new Promise((resolve, reject) => {
    // if (data.username === 'test' && data.password === '123456') {
    //   localStorage.setItem('loginInfo', JSON.stringify(data));
    //   commit('SET_USER_LOGIN_INFO', data);
    //   resolve(true);
    //   return true;
    // }
    localStorage.setItem('loginInfo', JSON.stringify(data));
    commit('SET_USER_LOGIN_INFO', data);
    resolve(true);
    return true;
  });
};

// 退出登陆
export const signOut = ({ commit }) => {
  localStorage.removeItem('loginInfo');
  commit('SET_USER_LOGIN_INFO', {});
};

// 判断是否登陆
export const isLogin = ({ commit }) => {
  const user = localStorage.getItem('loginInfo');
  if (user) {
    var userInfo = JSON.parse(user)
    commit('SET_USER_LOGIN_INFO', userInfo);
    // 更新保存用户信息
    getUserInfo().then((res2) => {
      if (res2.usrInfo.userId) {
        var userInfoNew = {
          userId: res2.usrInfo.userId,
          userName: res2.usrInfo.name,
          userMobile: res2.usrInfo.phone,
          token: userInfo.token,
          level: res2.usrInfo.level
        }
        commit('SET_USER_LOGIN_INFO', userInfoNew);
      }
    })
  }
};

// 获取首页推荐课程列表
export const loadHomeCourseList = ({ commit }, data) => {
  return new Promise((resolve, reject) => {
    getCourseList(data).then((res) => {
      commit('SET_HOME_COURSE_LIST', res.data.courses.slice(0,8));
    })
  });
};

// 获取首页推荐文章列表
export const loadHomeArticleList = ({ commit }, data) => {
  return new Promise((resolve, reject) => {
    getHomeArticleList(data).then((res) => {
      commit('SET_HOME_ARTICLE_LIST', res.slice(0,6));
    })
  });
};

// 获取首页推荐开眼列表
export const loadHomeOpeneyeList = ({ commit }, data) => {
  return new Promise((resolve, reject) => {
    getOpeneyeList(data).then((res) => {
      commit('SET_HOME_OPENEYE_LIST', res.data.openEyeDatas.slice(0,8));
    })
  });
};

// 获取首页推荐每日读书列表
export const loadHomeBookList = ({ commit }, data) => {
  return new Promise((resolve, reject) => {
    getBookList(data).then((res) => {
      commit('SET_HOME_BOOK_LIST', res.data.books[0].bookInfos.slice(0,5));
    })
  });
};


// 获取轮播图
export const loadBannerList = ({ commit }, data) => {
  return new Promise((resolve, reject) => {
    getBannerList(data).then((res) => {
      if (data.adType == 9) {
        commit('SET_BANNER_LIST', res.adList);
      } else if (data.adType == 10) {
        commit('SET_BANNER_LIST2', res.adList);
      }
    })
  });
};


// 获得顶部筛选分类
export const loadClassifyList = ({ commit }, data) => {
  return new Promise((resolve, reject) => {
    getClassifyList(data).then((res) => {
      commit('SET_CLASSIFY_LIST', res);
    })
  });
};

// 设置顶部当前分类Id
export const loadCurrentClassifyId = ({ commit }, data) => {
  return new Promise((resolve, reject) => {
    commit('SET_CURRENT_CLASSIFY_ID', data);
    resolve(true);
    return true;
  });
};

// 获取文章所有分类
export const loadArticleClassifyList = ({ commit }, data) => {
  return new Promise((resolve, reject) => {
    getDirtreeListByIcfs(data).then((res) => {
      commit('SET_ARTICLE_CLASSIFY_LIST', res.data.list);
      resolve(res.data.list);
    })
  });
};

// 设置文章当前选中分类
export const setArticleSelectCatId = ({ commit }, data) => {
  commit('SET_ARTICLE_SELECT_CATID', data);
}

// 获取文章当前选中分类列表
export const loadArticleListByCatId = ({ commit }, data) => {
  commit('SET_LOAD_STATUS', true);
  return new Promise((resolve, reject) => {
    getArticleListByCatId(data).then((res) => {
      commit('SET_ARTICLE_LIST', {
        rows: res.data.list,
        total: res.data.totalCount || 0
      });
      commit('SET_LOAD_STATUS', false);
      resolve(res);
    }).catch(() => {
      commit('SET_LOAD_STATUS', false);
    })
  });
};

// 获取搜索所有分类
export const loadSearchClassify = ({ commit }, data) => {
  return new Promise((resolve, reject) => {
    getSearchClassify(data).then((res) => {
      commit('SET_SEARCH_CLASSIFY_LIST', res.data.filter(item => [0,1,2,3,4,5].indexOf(item.idx) > -1 ));
      resolve(res.data);
    })
  });
};

// 设置搜索当前选中分类
export const setCurrentSearchClassifyId = ({ commit }, data) => {
  commit('SET_CURRENT_SEARCH_CLASSIFY_ID', data);
}

// 获取搜索当前选中分类列表
export const loadSearchList = ({ commit }, data) => {
  commit('SET_LOAD_STATUS', true);
  return new Promise((resolve, reject) => {
    getSearchList(data).then((res) => {
      commit('SET_SEARCH_LIST', res.data.searchResults);
      commit('SET_LOAD_STATUS', false);
      resolve(res);
    }).catch(() => {
      commit('SET_LOAD_STATUS', false);
    })
  });
};

// 获取课程列表
export const loadCourseList = ({ commit }, data) => {
  commit('SET_LOAD_STATUS', true);
  return new Promise((resolve, reject) => {
    getCourseList(data).then((res) => {
      commit('SET_COURSE_LIST', {
        rows: res.data.courses,
        total: res.data.totalCount || 0
      });
      commit('SET_LOAD_STATUS', false);
    }).catch(() => {
      commit('SET_LOAD_STATUS', false);
    })
  });
};

// 获取图书列表选中的分类
export const setSelectedBookType = ({ commit }, data) => {
  commit('SET_SELECTED_BOOK_TYPE', data)
}

// 获取图书列表
export const loadBookList = ({ commit }, data) => {
  commit('SET_LOAD_STATUS', true);
  return new Promise((resolve, reject) => {
    getBookList(data).then((res) => {
      commit('SET_BOOK_LIST', {
        rows: res.data.books[0].bookInfos,
        total: res.data.books[0].totalCount || 0
      });
      commit('SET_LOAD_STATUS', false);
    }).catch(() => {
      commit('SET_LOAD_STATUS', false);
    })
  });
};


// 获取开眼列表
export const loadOpeneyeList = ({ commit }, data) => {
  commit('SET_LOAD_STATUS', true);
  return new Promise((resolve, reject) => {
    getOpeneyeList(data).then((res) => {
      commit('SET_OPENEYE_LIST', {
        rows: res.data.openEyeDatas,
        total: res.data.totalCount || 0
      });
      commit('SET_LOAD_STATUS', false);
    }).catch(() => {
      commit('SET_LOAD_STATUS', false);
    })
  });
};

// 获取问答列表
export const loadWendaList = ({ commit }, data) => {
  commit('SET_LOAD_STATUS', true);
  return new Promise((resolve, reject) => {
    getWendaList(data).then((res) => {
      commit('SET_WENDA_LIST', {
        rows: res.data.list,
        total: res.data.totalCount || 0
      });
      commit('SET_LOAD_STATUS', false);
    }).catch(() => {
      commit('SET_LOAD_STATUS', false);
    })
  });
};